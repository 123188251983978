//ignore ts type
import React, { FC } from "react";
import { Link, graphql, StaticQuery } from "gatsby";
import Img from "gatsby-image";

const Gallery = ({ data }) => {

  return (
    <section
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        flexWrap: "wrap",
      }}
      className="booking"
    >
      {data.allFile.edges.map(({ node }) => {
        return (
          <Img
            style={{ margin: "1rem auto" }}
            key={node.id}
            fixed={node.childImageSharp.fixed}
            alt="home renewal in Italy and fun stuff"
          />
        );
      })}
    </section>
  );
};

export default Gallery;
export const query = graphql`
  query galleryQuery {
    allFile(filter: { sourceInstanceName: { eq: "gallery" } }) {
      edges {
        node {
          id
          childImageSharp {
            fixed(width: 300, height: 300) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`;
